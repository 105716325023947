import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { addItem } from "../service/topay";
import { amountToCents, fixCents } from "../../../services/amount";

class ToPayAdd extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        payto: "",
        description: "",
        amount: "",
      },
      errors: {},
      user: this.props.user,
      item: this.props.item,
      action: this.props.action,
      loading: false,
    };
  }

  schema = {
    payto: Joi.string().trim().required().label("Pay To"),
    description: Joi.string()
      .trim()
      .optional()
      .allow(null, "")
      .label("Description"),
    amount: Joi.number().min(1).required().label("Amount"),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  doSubmit = async () => {
    const { data, user, action } = this.state;
    this.setState({ loading: true });
    // Call the server
    try {
      let created = new Date();
      let description = data.description.trim();
      let payto = data.payto.trim();
      let timestamp = created.getTime();
      let amountString = fixCents(data.amount);
      let amount = Number(amountString);
      let amountCents = amountToCents(amount);
      const transaction = {
        created,
        amountString,
        amountCents,
        amount,
        header: "Added total amount to be paid",
        timestamp,
        operator: "+",
      };
      const item = {
        transactionAmount: amount,
        amountString,
        amountCents,
        amount,
        created,
        description,
        payto,
        timestamp,
        transactions: transaction,
      };

      await addItem(user.uid, item);
      action();
    } catch (ex) {
      console.error(`Unable to Set Data: ${ex}`);
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <React.Fragment>
          <h3 className="py-3">Add amount to pay</h3>

          <form onSubmit={this.handleSubmit}>
            {this.renderInputFocus("payto", "Pay to", "text", "")}
            {this.renderInput("amount", "Amount", "text", "")}
            {this.renderInput("description", "Description", "text", "Optional")}
            {!loading && this.renderBlockButton("Save")}
            {loading && this.renderLoadingBlockButton("Saving...")}
          </form>
          <Button
            variant="link"
            onClick={() => this.props.history.goBack()}
            className="btn-block btn-lg my-4"
          >
            Cancel
          </Button>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(ToPayAdd);
