export function displayAmount(amount) {
  return Number(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
}

export function fixCents(amount) {
  return Number(amount).toFixed(2);
}

export function amountToCents(amount) {
  let whole = parseInt(amount);
  let decimal = amount % 1;
  let cents = 0;
  if (decimal === 0) {
    //whole number, no decimal
    cents = whole * 100;
    return cents;
  }
  return parseInt(amount * 100);
}

export function computeTotalAmountToPay(items) {
  let sum = 0;
  items.forEach((item) => {
    if (item.hasOwnProperty("amount")) {
      sum += parseFloat(item["amount"]);
    }
  });

  return sum;
}

export function getAmount(obj) {
  return Number(obj[0].amount);
}

export function getCurrency(obj) {
  return Number(obj[0].currency);
}

export function getAmountWithCurrency(obj, amount) {
  return `${obj[0].currency} ${amount}`;
}
