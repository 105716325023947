import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import { withRouter } from "react-router-dom";
import LinkButton from "../../blocks/linkButtonBlock";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Button, Modal } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteItem, updateItem } from "../service/tocollect";
import { displayAmount, fixCents } from "../../../services/amount";

class ToCollectEdit extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        payto: this.props.item.header,
        description: this.props.item.description,
      },
      amount: fixCents(this.props.item.amount),
      currency: this.props.item.currency,
      errors: {},
      item: this.props.item,
      loading: false,
      itemsave: false,
      itemdelete: false,
      deleting: false,
      showmodal: false,
    };
  }

  schema = {
    payto: Joi.string().trim().required().label("Collect from"),
    description: Joi.string()
      .trim()
      .optional()
      .allow(null, "")
      .label("Description"),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  doSubmit = async () => {
    const { data, item } = this.state;
    this.setState({ loading: true });
    // Call the server
    try {
      //remove any spaces inside the id
      let payto = data.payto.trim();
      let description = data.description.trim();

      await updateItem(item.id, { payto, description });
      //set state to refresh page
      this.setState({ itemsave: true, loading: false });
    } catch (ex) {
      console.error("Unable to Set Data");
    }
  };

  doDelete = async () => {
    const { item } = this.state;
    this.setState({ deleting: true });
    // Call the server
    try {
      await deleteItem(item.id);
      //set state to refresh page
      this.setState({ showmodal: false, itemdelete: true, deleting: false });
    } catch (ex) {
      console.error("Unable to Set Data");
    }
  };

  handleDelete = () => {
    this.setState({ showmodal: !this.state.showmodal });
  };

  handleClose = () => {
    this.setState({ deleting: false, showmodal: false });
  };

  showModal = () => {
    const { showmodal, deleting } = this.state;
    if (showmodal === false) {
      return null;
    }
    return (
      <React.Fragment>
        <Modal
          show={showmodal}
          onHide={this.handleClose}
          animation={false}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title className="text-danger font-weight-bold">
              Are you sure?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <span className="font-weight-bold">This cannot be undone. </span>All
            the transactions associated with this will also be deleted.
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={deleting}
              variant="outline-secondary"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            {deleting !== true && (
              <Button variant="danger" onClick={() => this.doDelete()}>
                Yes, Delete
              </Button>
            )}
            {deleting === true && (
              <Button disabled variant="danger" onClick={() => this.doDelete()}>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Deleting...
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  render() {
    const { loading, itemsave, itemdelete, amount, currency } = this.state;
    return (
      <div>
        {!itemsave && !itemdelete && (
          <React.Fragment>
            <h3 className="py-3">Edit</h3>

            <form onSubmit={this.handleSubmit}>
              <p className="text-left mb-0">Amount</p>
              <h3 className="text-danger">
                {currency} {displayAmount(amount)}
              </h3>

              {this.renderInputFocus(
                "payto",
                "Collect from",
                "text",
                "Collect from"
              )}
              {this.renderInput(
                "description",
                "Description",
                "text",
                "Optional"
              )}
              {!loading && this.renderBlockButton("Save")}
              {loading && this.renderLoadingBlockButton("Saving...")}
            </form>
            <Button
              variant="link"
              onClick={() => this.props.history.goBack()}
              className="btn-block btn-lg my-4"
            >
              Cancel
            </Button>
            <button
              onClick={() => this.handleDelete()}
              className="btn-block btn-lg text-muted mt-4 p-0"
              style={{
                border: "none",
                background: "none",
                backgroundColor: "none",
              }}
            >
              <DeleteIcon style={{ fontSize: "20px", marginTop: "-4px" }} />{" "}
              Delete item
            </button>
            {this.showModal()}
          </React.Fragment>
        )}

        {itemsave && (
          <React.Fragment>
            <div className="text-center pt-4">
              <CheckCircleOutlineIcon
                style={{ fontSize: "150px", color: "green" }}
              />
              <h3 className="my-4">This has been successfully saved!</h3>
              <button
                type="button"
                onClick={() => this.props.history.replace("/tocollect/viewall")}
                className="btn btn-primary mt-4 btn-block btn-lg"
              >
                Done
              </button>

              <LinkButton type="link" link="/home" label="Back to Home" />
            </div>
          </React.Fragment>
        )}
        {itemdelete && (
          <React.Fragment>
            <div className="text-center pt-4">
              <CheckCircleOutlineIcon
                style={{ fontSize: "150px", color: "green" }}
              />
              <h3 className="my-4">This has been successfully deleted!</h3>
              <button
                type="button"
                onClick={() => this.props.history.replace("/tocollect/viewall")}
                className="btn btn-primary mt-4 btn-block btn-lg"
              >
                Done
              </button>

              <LinkButton type="link" link="/home" label="Back to Home" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(ToCollectEdit);
