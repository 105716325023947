import React from "react";
import logo from "../../img/logo/bayadnow-00.png";

const SystemLogo = () => {
  return (
    <nav className="navbar navbar-light  flex-column">
      <a className="navbar-brand mx-auto" href="# ">
        <img
          src={logo}
          width="300px"
          // height="120px"
          alt="BayadNow Logo"
        />
      </a>
    </nav>
  );
};

export default SystemLogo;
