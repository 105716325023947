export function getTransactionToPayType() {
  return "topay";
}

export function getTransactionToCollectType() {
  return "tocollect";
}

export function getTransactionHeader(obj) {
  return obj[0].header;
}

export function getTransactions(obj) {
  return obj[0].transactions;
}

export function getTransactionAmount(obj) {
  return Number(obj[0].transactionAmount);
}

export function getPayAllButtonLabel(obj) {
  //first item is always "Added new record"
  return obj.length === 1 ? "Pay full amount" : "Pay remaining balance";
}

export function getCollectAllButtonLabel(obj) {
  //first item is always "Added new record"
  return obj.length === 1
    ? "Collected full amount"
    : "Collected remaining balance";
}

export function getPayAllDescription(obj, amount) {
  //first item is always "Added new record"
  return obj.length === 1 ? "Paid full amount" : `Paid remaining ${amount}`;
}

export function getPayPartialDescription(amount) {
  return `Paid partial amount ${amount}`;
}

export function getCollectPartialDescription(amount) {
  return `Collected partial amount ${amount}`;
}
export function getTransactionOperator(obj) {
  return obj.operator === "-" ? "-" : "";
}
