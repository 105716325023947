import React, { Component } from "react";
import { getCurrentUserData, userLogout } from "../../../services/user";
import ToCollectEdit from "../component/toCollectEdit";
import Header from "../../common/header";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { getItemToPay } from "../service/tocollect";
import { getTransactionToCollectType } from "../../../services/transaction";
import { withRouter } from "react-router-dom";

class ToCollectEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        payto: "",
        amount: 0,
        description: "",
      },
      id: this.props.match.params.id,
      user: this.props.user,
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const { userid } = user;
      const { id } = this.state;
      const item = await getItemToPay(
        userid,
        id,
        getTransactionToCollectType()
      );
      if (item?.length) {
        this.setState({
          data: {
            payto: item[0].header,
            description: item[0].description,
            amount: item[0].amount,
          },
          item: item[0],
          loading: false,
        });
      } else {
        this.props.history.push("/tocollect/viewall");
      }
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async componentDidMount() {
    await this.getCurrentUserData();
  }

  render() {
    const { loading, user, item } = this.state;
    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backonly"
          smallpic={user.photoURL}
          hide="profile"
        />
      );
    }
    return (
      <React.Fragment>
        <Header type="backonly" smallpic={user.photoURL} hide="profile" />

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-3">
                <ToCollectEdit item={item} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ToCollectEditPage);
