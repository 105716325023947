import { getCurrentUser, updateCurrentUserPhotos } from "./user";

export async function updateProfilePic(userid, filename) {
  const currentUser = await getCurrentUser();
  const imageDir = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images`;
  const thumb = encodeURIComponent(`/${userid}/thumbs/${userid}_60x60.jpg`);
  const photo = encodeURIComponent(`/${userid}/${filename}`);
  const smallPic = `${imageDir}${thumb}?alt=media`;
  const bigPic = `${imageDir}${photo}?alt=media`;

  await currentUser.updateProfile({ photoURL: smallPic });
  await updateCurrentUserPhotos(userid, smallPic, bigPic);
}
