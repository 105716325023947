import React, { Component } from "react";
import { getCurrentUserData, userLogout } from "../../../services/user";
import Header from "../../common/header";
import { getAllToPaySettled } from "../service/tocollect";
import ToCollectCard from "../component/toCollectCard";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { getTransactionToCollectType } from "../../../services/transaction";
import { withRouter } from "react-router-dom";

class ToCollectViewPaidPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        email: "",
        dob: "",
        mobile: "+65",
      },
      errors: {},
      items: [],
      loading: true,
    };
  }

  async getItems() {
    const uid = this.state.user.uid;
    return await getAllToPaySettled(uid, getTransactionToCollectType());
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        userid,
        created,
        fullname,
        email,
        mobile,
        dob,
        // totaltopay,
      } = user;
      const items = await this.getItems();
      // let computedTotal = computeTotalAmountToPay(items);
      // //update total
      // console.log(`totaltopay: ${totaltopay} computed: ${computedTotal}`);
      // if (totaltopay !== computedTotal) {
      //   //save if no change happen
      //   await updateTotal(userid, computedTotal);
      // }
      this.setState({
        data: {
          userid,
          created,
          fullname,
          email,
          mobile,
          dob,
        },
        // totaltopay: fixCents(computedTotal),
        items,
        loading: false,
        user: this.state.user,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, items, user } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backonly"
          smallpic={user.photoURL}
          hide="profile"
        />
      );
    }
    // let toPay = totaltopay;
    const active = items.filter((item) => item.settledfull === true);
    // const settled = (items?.length || 0) - (active?.length || 0);
    return (
      <React.Fragment>
        <Header type="backonly" smallpic={user.photoURL} hide="profile" />

        <div className="row justify-content-center  mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-3">
                <h3 className="py-3">Collected transactions</h3>

                {/* <MainPanel
                  text="To Pay"
                  amount={toPay}
                  hidelink={Number(settled) === 0}
                  viewLink="/topay/view/transactions/paid"
                  viewLinkText="View fully paid transactions"
                /> */}
                <ToCollectCard items={active} paid={true} hideall={true} />
                {/* <BottomPanel
                  link="/topay/add"
                  text="Add new amount to pay"
                  show={Number(totaltopay) === 0}
                /> */}
                <button
                  type="button"
                  onClick={() => this.props.history.goBack()}
                  className="btn btn-primary mt-4 btn-block btn-lg"
                >
                  Go back
                </button>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ToCollectViewPaidPage);
