import fire from "./fire";
import { isValidEmail, isValidParish } from "./validation";
import { get4DigitId } from "../utils/utils";

export async function isUserLoggedIn() {
  const currentUser = await fire.auth().currentUser;
  if (currentUser) {
    return true;
  }
  return false;
}

export async function userLogout() {
  return await fire.auth().signOut();
}

export async function getCurrentUser() {
  return await fire.auth().currentUser;
}

export async function getCurrentUserData(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    return user.data();
  } else {
    return null;
  }
}

export async function getParishioners(parish) {
  if (!isValidParish(parish)) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("parish", "==", parish)
    //.orderBy("fullname", "asc")
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function createWithEmailAndPassword(email, password) {
  // await fire.auth().createUserWithEmailAndPassword(email, password);
  let errors;

  try {
    const result = await fire
      .auth()
      .createUserWithEmailAndPassword(email, password);
    // throw "Forced error"; //{ code: "/forced-error", email: "Forced Error!" };
    errors = { code: "success", user: result.user };
  } catch (ex) {
    if (ex.code === "auth/email-already-in-use") {
      errors = {
        email: `${ex.message} Please try to login instead.`,
        code: ex.code,
        message: ex.message,
      };
    } else if (ex.code === "auth/invalid-email") {
      errors = { email: ex.message, code: ex.code, message: ex.message };
    } else if (ex.code === "auth/operation-not-allowed") {
      errors = { email: ex.message, code: ex.code, message: ex.message };
    } else if (ex.code === "auth/weak-password") {
      errors = {
        password: "Password is too weak",
        code: ex.code,
        message: ex.message,
      };
    } else {
      errors = {
        email: "Unknown error. Please try again later.",
        code: ex.code,
        message: ex.message,
      };
    }
  }
  return errors;
}

export async function getUserByEmailInAuth(email) {
  let emailtrim = email.toLowerCase().trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("email", "==", emailtrim)
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}
export async function getUserByUid(uid) {
  var list = [];
  if (uid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("userid", "==", uid)
    .get();

  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersBySubid(subid) {
  var list = [];
  if (subid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("subid", "==", subid.toUpperCase().trim())
    .orderBy("fullname", "asc")
    .get();

  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersByMobile(mobile) {
  var list = [];
  if (mobile === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("mobile", "==", mobile.trim())
    .orderBy("fullname", "asc")
    .get();

  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserByEmail(email) {
  let emailtrim = email.toLowerCase().trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }
  //call admin cloud function here
  let foundemail = emailtrim;
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("email", "==", foundemail)
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserMassBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("parishionerbookings")
    .where("parishionerid", "==", uid)
    .orderBy("massdate", "desc")
    .get();

  var list = [];
  bookings.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function updateUserEmail(userId, email) {
  const db = fire.firestore();

  //1. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();

      transaction.update(userRef, {
        lastupdate: created,
        email,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateAccountIdentification(userId, id, secureid) {
  let subId = get4DigitId(id);

  const db = fire.firestore();
  //1. update parishionerbookings
  const parishionerBookingRef = await db.collection("parishionerbookings");
  let result = await parishionerBookingRef
    .where("parishionerid", "==", userId)
    .get();
  //update identification and subid
  result.forEach(async (doc) => {
    console.log("Doc: ", doc);
    await parishionerBookingRef
      .doc(doc.id)
      .update({ identification: secureid, subid: subId });
  });
  //3. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();

      transaction.update(userRef, {
        lastupdate: created,
        identification: secureid,
        subid: subId,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateParishDetail(userId, data) {
  const db = fire.firestore();
  console.log("Data: ", data);

  //1. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();
      const { parish } = data;

      transaction.update(userRef, {
        lastupdate: created,
        parish,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateBasicAccountDetails(userId, data) {
  const db = fire.firestore();
  console.log("Data: ", data);
  //1. update feedback
  const feedbackRef = await db.collection("feedback");
  let result = await feedbackRef.where("authorid", "==", userId).get();
  result.forEach(async (doc) => {
    console.log("Doc: ", doc);
    await feedbackRef.doc(doc.id).update({ author: data.fullname });
  });
  //2. update parishionerbookings
  const parishionerBookingRef = await db.collection("parishionerbookings");
  result = await parishionerBookingRef
    .where("parishionerid", "==", userId)
    .get();
  result.forEach(async (doc) => {
    console.log("Doc: ", doc);
    await parishionerBookingRef.doc(doc.id).update({ fullname: data.fullname });
  });
  //3. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();
      const { fullname, dob, mobile } = data;

      transaction.update(userRef, {
        lastupdate: created,
        fullname: fullname.trim(),
        dob: dob.trim(),
        mobile: mobile.trim(),
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateBasicUserDetailsByTransaction(userId, data) {
  const db = fire.firestore();
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }
      // const fbdoc = await transaction
      //   .where("authorid", "==", userId)
      //   .get(feedbackRef);
      // if (!fbdoc.exists) {
      //   console.log("authorid not found.");
      //   // throw "Document does not exist!";
      //   // return { status: 1, message: "User not found." };
      // }

      const created = new Date().getTime();
      const { fullname, dob, mobile } = data;
      //TODO: 1. update parishioner collection
      /*
      parishioner.fullname = fullname
      parishioner.parish = parish
      parishioner.parishcode
      */
      //TODO: 2. update feedback collection
      /*
     feedback.author = fullname
     */
      transaction.update(userRef, {
        lastupdate: created,
        fullname,
        dob,
        mobile,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateCurrentUserPhotos(uid, smallPic, bigPic) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ smallpic: smallPic, bigpic: bigPic });
    return getCurrentUser();
  } else {
    return null;
  }
}
