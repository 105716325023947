import React, { Component } from "react";
import { Button } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router-dom";

class BottomPanel extends Component {
  render() {
    const { link, text, show } = this.props;
    if (!show) {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          variant="primary"
          onClick={() => this.props.history.push(link)}
          className="btn-block btn-lg my-4"
        >
          <AddIcon
            style={{
              marginTop: "-2px",
              marginRight: "0px",
            }}
          />{" "}
          {text}
        </Button>
      </React.Fragment>
    );
  }
}

export default withRouter(BottomPanel);
