import React, { Component } from "react";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import CakeOutlinedIcon from "@material-ui/icons/CakeOutlined";
import AlarmOutlinedIcon from "@material-ui/icons/AlarmOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import { maskDOB, mask4DigitId } from "../../utils/utils";
import { getParishNameById } from "../../services/parish";
import { isValidParish } from "../../services/validation";

class MyProfileCardPanel extends Component {
  constructor(props) {
    super(props);
    const {
      fullname,
      email,
      mobile,
      dob,
      identification,
      subid,
      parish,
      // preferredmasstiming,
    } = props.data;

    this.state = {
      fullname,
      email,
      mobile,
      dob,
      identification,
      subid,
      parish,
      // preferredmasstiming,
      verifiedemail: this.props.verifiedemail,
    };
  }

  isCompleteProfileDetails() {
    let { fullname, email, mobile, dob, identification, parish } = this.state;
    const isComplete =
      fullname && email && dob && mobile && identification && parish;
    return isComplete;
  }

  getIconByField(field) {
    let icon;
    if (field === "email") {
      icon = <MailOutlineIcon style={{ color: "#277AF5", fontSize: "24" }} />;
    } else if (field === "mobile") {
      icon = <CallOutlinedIcon style={{ color: "#277AF5", fontSize: "24" }} />;
    } else if (field === "identification") {
      icon = (
        <AssignmentIndOutlinedIcon
          style={{ color: "#277AF5", fontSize: "24" }}
        />
      );
    } else if (field === "dob") {
      icon = <CakeOutlinedIcon style={{ color: "#277AF5", fontSize: "24" }} />;
    } else if (field === "parish") {
      icon = (
        <AccountBalanceOutlinedIcon
          style={{ color: "#277AF5", fontSize: "24" }}
        />
      );
    } else if (field === "preferredmasstiming") {
      icon = <AlarmOutlinedIcon style={{ color: "#277AF5", fontSize: "24" }} />;
    }

    return icon;
  }

  displayProfileDetails(label, field, value) {
    const { verifiedemail } = this.state;
    return (
      <React.Fragment>
        <div
          className="pt-1 float-left"
          style={{ height: "50px", width: "40px" }}
        >
          {this.getIconByField(field)}
        </div>
        <div className="pt-1  text-left">
          {value}
          <br />
          <span className="text-muted">{label}</span>{" "}
          {field === "email" && verifiedemail === true && (
            <span className="badge badge-pill badge-success">Verified</span>
          )}
          {field === "email" && verifiedemail === false && (
            <span className="badge badge-pill badge-danger">Unverified</span>
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    let {
      email,
      mobile,
      dob,
      identification,
      subid,
      parish,
      // preferredmasstiming,
    } = this.state;

    if (!isValidParish(parish)) {
      /*
      override preferredmasstiming if parish is not valid
      to avoid display of preferred mass timing
      */
      // preferredmasstiming = null;
    }

    // const showComplete = this.isCompleteProfileDetails();

    var maskId, maskDob;
    if (subid) {
      maskId = mask4DigitId(subid);
    }
    if (dob) {
      maskDob = maskDOB(dob);
    }

    return (
      <div className="card mb-4">
        <ul className="list-group list-group-flush">
          {email && (
            <li className="list-group-item">
              {this.displayProfileDetails(
                "Email",
                "email",
                email.toLowerCase()
              )}
            </li>
          )}
          {mobile && (
            <li className="list-group-item">
              {this.displayProfileDetails("Mobile Number", "mobile", mobile)}
            </li>
          )}
          {identification && (
            <li className="list-group-item">
              {this.displayProfileDetails(
                "NRIC/FIN/Passport",
                "identification",
                maskId
              )}
            </li>
          )}
          {dob && (
            <li className="list-group-item">
              {this.displayProfileDetails("Year of Birth", "dob", maskDob)}
            </li>
          )}
          {parish && (
            <li className="list-group-item">
              {this.displayProfileDetails(
                "Parish",
                "parish",
                getParishNameById(parish)
              )}
            </li>
          )}
          {/* {preferredmasstiming && (
            <li className="list-group-item">
              {this.displayProfileDetails(
                "Preferred Weekend Mass Timing ",
                "preferredmasstiming",
                getPreferredMassById(parish, preferredmasstiming)
              )}
            </li>
          )} */}
        </ul>

        {/* {!showComplete && (
          <Link className="text-center py-2" to="/account/setup">
            Complete Setup
          </Link>
        )} */}
      </div>
    );
  }
}

export default MyProfileCardPanel;
