import React, { Component } from "react";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import AddAlertOutlinedIcon from "@material-ui/icons/AddAlertOutlined";
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import HowToRegOutlinedIcon from "@material-ui/icons/HowToRegOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import PlaylistAddCheckOutlinedIcon from "@material-ui/icons/PlaylistAddCheckOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";

class MainMenuCardSimplePanel extends Component {
  constructor(props) {
    super(props);
    const {
      external,
      menulink,
      menulinklabel,
      menuicon,
      badgelabel,
      menutype,
    } = this.props;

    this.state = {
      external,
      menutype,
      menulink,
      menulinklabel,
      menuicon,
      badgelabel,
      badge: {
        height: "25px",
        weight: "normal",
        width: "110px",
        type: "light",
      },
      icon: {
        color: "#277AF5",
        size: "45px",
        paddingright: "15px",
      },
    };
  }

  displayBadge() {
    const { badgelabel } = this.state;
    return (
      <span
        className={`defaultsmallfontsize font-weight-normal badge badge-pill badge-danger mt-2`}
      >
        {badgelabel}
      </span>
    );
  }

  displayMenuLogo() {
    const { menuicon, icon } = this.state;
    let logo;
    if (menuicon === "registermass") {
      logo = (
        <EditOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "parishinfo") {
      logo = (
        <AccountBalanceOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "feedback") {
      logo = (
        <FeedbackOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "support") {
      logo = (
        <FavoriteBorderOutlinedIcon
          style={{
            // color: "red",
            fontSize: icon.size,
            paddingRight: icon.paddingright,
          }}
        />
      );
    } else if (menuicon === "announcement") {
      logo = (
        <AddAlertOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "postannouncement") {
      logo = (
        <RecordVoiceOverOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "parishioners") {
      logo = (
        <PeopleOutlineOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "massregistrations") {
      logo = (
        <HowToRegOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "dashboard") {
      logo = (
        <DnsOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "priests") {
      logo = (
        <GroupAddOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "mymassbookings") {
      logo = (
        <PlaylistAddCheckOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "roles") {
      logo = (
        <LockOpenOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "configuremass") {
      logo = (
        <BackupOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "massbyparish") {
      logo = (
        <AddBoxOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "accountmanager") {
      logo = (
        <BallotOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "mybookings") {
      logo = (
        <EventNoteIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "stats") {
      logo = (
        <AssessmentOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "safeentry") {
      logo = (
        <ExitToAppOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "currentcalendar") {
      logo = (
        <EventAvailableOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "pastcalendar") {
      logo = (
        <CalendarTodayOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    } else if (menuicon === "addmassschedule") {
      logo = (
        <PostAddOutlinedIcon
          style={{ fontSize: icon.size, paddingRight: icon.paddingright }}
        />
      );
    }
    return logo;
  }

  displayLink() {
    const { menulink, menulinklabel } = this.state;
    return (
      <Link
        className="mt-3 btn-lg btn btn-block btn-outline-primary "
        role="button"
        to={menulink}
      >
        {menulinklabel}
      </Link>
    );
  }

  render() {
    const {
      external,
      menulink,
      menulinklabel,
      menutype,
      badgelabel,
    } = this.state;
    let style =
      "text-truncate py-4 my-3 text-left btn-lg btn btn-block btn-outline-primary ";
    if (menutype) {
      style += " lightframecolor";
    }
    if (external === true) {
      return (
        <a
          target="_blank"
          className={style}
          rel="noopener noreferrer"
          href={menulink}
        >
          <div className="float-left">{this.displayMenuLogo()}</div>
          <div className="pt-2">
            <span className="">{menulinklabel}</span>{" "}
            {badgelabel && this.displayBadge()}
          </div>
        </a>
      );
    }
    return (
      <Link className={style} role="button" to={menulink}>
        <div className="float-left">{this.displayMenuLogo()}</div>
        <div className="pt-2">
          <span className="font-weight-bold ">{menulinklabel}</span>{" "}
          {badgelabel && this.displayBadge()}
        </div>
      </Link>
      //   <div className="card pt-2 text-center px-3 my-3">
      //     {badgelabel && this.displayBadge()}
      //     <div className="card-body">
      //       {this.displayMenuLogo()}
      //       {this.displayLink()}
      //     </div>
      //   </div>
    );
  }
}

export default MainMenuCardSimplePanel;
