import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

const ProfileFullname = ({ isvalidfullname, fullname }) => {
  return (
    <React.Fragment>
      <h2 className="text-center text-wrap">
        {isvalidfullname ? (
          fullname
        ) : (
          <Link to="/account/edit">
            <strong className="text-danger">
              {fullname} <EditIcon />
            </strong>
          </Link>
        )}
      </h2>
    </React.Fragment>
  );
};

export default ProfileFullname;
