import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fixCents, displayAmount } from "../../../services/amount";
import { formatTime, getNormalShortDate } from "../../../utils/utils";

class ToCollectCard extends Component {
  render() {
    const {
      items,
      hideedit,
      hidepay,
      hideall,
      hidepaypartial,
      paid,
    } = this.props;
    let amountColor = "text-danger";
    return (
      <React.Fragment>
        {items.map((item) => {
          let amount = fixCents(item.amount);
          let badge = null;
          let timestamp = item.lastupdate || item.timestamp;
          if (paid || item.amount === 0) {
            timestamp = item.lastupdate;
            amount = fixCents(item.transactionAmount);
            badge = (
              <span className="badge badge-pill badge-success">Paid</span>
            );
          }
          if (item.amount === 0) {
            amountColor = null;
          }
          let timeAgo = formatTime(timestamp);
          let date = getNormalShortDate(new Date(timestamp));
          if (timeAgo === "Just now") {
            timeAgo = (
              <React.Fragment>
                <span className="badge badge-pill badge-success">
                  {formatTime(timestamp)}
                </span>
              </React.Fragment>
            );
          } else {
            timeAgo = (
              <span>
                {date} &bull; {timeAgo}
              </span>
            );
          }
          return (
            <div key={item.id} className="card my-3 py-2">
              <div className="card-body">
                <div className="float-left font-weight-light text-truncate standardfontsize text-muted">
                  {timeAgo}
                </div>
                <div className="float-right">{badge}</div>
                <div className="clearfix"></div>
                <div className="float-left font-weight-bold text-muted">
                  <Link
                    className="averagefontsize"
                    to={`/tocollect/view/${item.id}`}
                  >
                    {item.header}
                  </Link>
                </div>
                <div className={`float-right font-weight-bold ${amountColor}`}>
                  <Link
                    className="averagefontsize"
                    to={`/tocollect/view/${item.id}`}
                  >
                    {item.currency} {displayAmount(amount)}
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div className="float-left font-weight-light clearfix text-muted pb-2">
                  {item.description}
                </div>
                {!hideall && (
                  <React.Fragment>
                    <div className="clearfix"></div>
                    <div className="mt-4 float-right font-weight-normal clearfix">
                      {/* {!hideview && (
                        <Link
                          className="ml-2 btn-sm btn btn-outline-light text-muted"
                          to={`/tocollect/view/${item.id}`}
                        >
                          View
                        </Link>
                      )} */}{" "}
                      {!hideedit && (
                        <Link
                          className="ml-2 btn-sm btn btn-outline-light text-muted"
                          style={{ border: "1px solid #ddd" }}
                          to={`/tocollect/edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      )}{" "}
                      {!hidepaypartial && (
                        <Link
                          className="ml-2 btn-sm btn btn-outline-light text-muted"
                          style={{ border: "1px solid #ddd" }}
                          role="button"
                          to={`/tocollect/collectpartial/${item.id}`}
                        >
                          Collect Partial
                        </Link>
                      )}{" "}
                      {!hidepay && (
                        <Link
                          className="ml-2 btn-sm btn btn-outline-success"
                          role="button"
                          to={`/tocollect/collectfull/${item.id}`}
                        >
                          Collect Full
                        </Link>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default ToCollectCard;
