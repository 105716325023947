import React, { Component } from "react";
import { getCurrentUserData, userLogout } from "../../../services/user";
import Header from "../../common/header";
import { getItemToPay } from "../service/tocollect";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import {
  getTransactions,
  getTransactionAmount,
  getTransactionToCollectType,
} from "../../../services/transaction";
import ToPayTransactionList from "../component/transactionList";
import {
  displayAmount,
  getAmount,
  getAmountWithCurrency,
} from "../../../services/amount";
import BottomPrompt from "../../mass/component/bottomPrompt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ToCollectCard from "../component/toCollectCard";
import { withRouter } from "react-router-dom";

class ToCollectViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      id: this.props.match.params.id,
      data: {
        fullname: "",
        email: "",
        dob: "",
        mobile: "+65",
      },
      errors: {},
      items: [],
      loading: true,
    };
  }

  async getItem() {
    const uid = this.state.user.uid;
    const id = this.state.id;
    return await getItemToPay(uid, id, getTransactionToCollectType());
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const { userid, created, fullname, email, mobile, dob } = user;
      const items = await this.getItem();
      this.setState({
        data: {
          userid,
          created,
          fullname,
          email,
          mobile,
          dob,
        },
        // totaltopay: computedTotal.toFixed(2),
        items,
        loading: false,
        user: this.state.user,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, items, user } = this.state;
    if (loading === true) {
      return (
        <LoadingSpinnerNav
          // type="backandlogo"
          smallpic={user.photoURL}
          hide="profile"
          invisiblehome={true}
        />
      );
    }
    let amount = getAmount(items);
    let paid = amount === 0 ? true : false;
    let header; // = <h1 className="pt-3 pb-2">{getTransactionHeader(items)}</h1>;
    if (paid) {
      header = (
        <div className="text-center font-weight-bold my-4">
          <CheckCircleIcon
            className="text-success text-center"
            style={{ fontSize: "100px" }}
          />
          <h4 className="text-danger">
            {getAmountWithCurrency(
              items,
              displayAmount(getTransactionAmount(items))
            )}
          </h4>
          FULLY PAID
        </div>
      );
    }
    return (
      <React.Fragment>
        <Header
          //  type="backandlogo"
          smallpic={user.photoURL}
          hide="profile"
          invisiblehome={true}
        />

        <div className="row justify-content-center  mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-3">
                {header}
                <ToCollectCard
                  items={items}
                  // paid={paid}
                  hideview={true}
                  hideedit={true}
                  hideall={Number(amount) === 0}
                />
                <h4 className="pt-2 pb-2 text-muted">Transaction History</h4>
                <ToPayTransactionList items={getTransactions(items)} />
              </div>
            </main>
            <BottomPrompt
              label="Done"
              selected="selected"
              handleButtonContinue={() =>
                this.props.history.replace("/tocollect/viewall")
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ToCollectViewPage);
