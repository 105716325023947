import React, { Component } from "react";
import { Link } from "react-router-dom";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import { displayAmount } from "../../../services/amount";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";

class MainPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text,
      amount: this.props.amount,
      type: this.props.type,
      hidelink: this.props.hidelink,
      viewLink: this.props.viewLink,
      viewLinkText: this.props.viewLinkText,
      description: this.props.description,
    };
  }

  doToAddCollect = () => {
    this.props.history.push("/tocollect/add");
  };

  doToAddPay = () => {
    this.props.history.push("/topay/add");
  };

  render() {
    const {
      text,
      description,
      amount,
      type,
      hidelink,
      viewLink,
      viewLinkText,
    } = this.state;
    let action = this.doToAddPay;
    let color = "success";
    let icon = (
      <TrendingDownIcon
        className={`ml-0 alert-${color}`}
        style={{
          marginTop: "-6px",
          marginRight: "0px",
        }}
      />
    );
    if (type === "in") {
      color = "primary";
      // link = "/tocollect/view";
      action = this.doToAddCollect;
      icon = (
        <TrendingUpIcon
          className={`ml-0 alert-${color}`}
          style={{
            marginTop: "-6px",
            marginRight: "0px",
          }}
        />
      );
    }
    icon = null;
    return (
      <React.Fragment>
        <div
          className={`alert alert-${color} my-3 py-4 col clearfix`}
          role="alert"
        >
          <div className="float-left">
            <h4 className="mb-0 pb-0">
              {icon}
              {` ${text}`}
            </h4>
            <p className="text-muted">{description}</p>
          </div>
          <div className="float-right">
            <Button
              variant={`${color}`}
              onClick={action}
              className="btn-sm btn-circle"
            >
              <AddIcon
                style={{
                  marginTop: "-2px",
                  marginRight: "0px",
                }}
              />
            </Button>{" "}
          </div>
          <div className="clearfix py-4"></div>
          <h1 className="mb-0 text-right font-weight-bold">
            {viewLink && (
              <Link className={`alert-${color}`} to={viewLink}>
                {displayAmount(amount)}
              </Link>
            )}
            {!viewLink && displayAmount(amount)}
          </h1>
          {!hidelink && (
            <div className="clearfix float-right pt-1">
              <Link
                className={`text-muted`}
                style={{ textDecoration: "none" }}
                to={viewLink}
              >
                <ListOutlinedIcon
                  style={{
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "0px",
                  }}
                />{" "}
                {viewLinkText}
              </Link>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(MainPanel);
