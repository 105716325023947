import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import LinkButton from "../../blocks/linkButtonBlock";
import LoadingSpinner from "../../common/loadingSpinner";
import SimpleMessage from "../../blocks/simpleMessageBlock";
import SystemLogo from "../../blocks/systemLogoBlock";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";

class AuthHandler extends Form {
  state = {
    data: { password: "" },
    query: { mode: "", lang: "en", code: "", email: "" },
    errors: {},
    loading: true,
    validaction: false,
    resetsuccess: false,
    validatesuccess: false,
    authcommand: "invalid",
  };

  schema = {
    password: Joi.string().min(8).required().label("New Password"),
  };

  doSubmit = () => {
    // Call the server
    const { errors } = this.state;
    this.setState({
      errors,
      loading: true,
    });
    this.handleAuth();
  };

  handleAuth = () => {
    let mode = this.state.query.mode;
    if (mode === "resetPassword") {
      this.handleResetPassword();
    } else if (mode === "verifyEmail") {
      this.handleVerifyEmail();
    }
  };

  handleResetPassword = () => {
    const { code } = this.state.query;
    const { password } = this.state.data;
    const auth = fire.auth();
    auth
      .confirmPasswordReset(code, password)
      .then((resp) => {
        logEvent("password_reset_success", {
          message: "Reset success",
        });
        this.setState({
          loading: false,
          validaction: true,
          resetsuccess: true,
        });
        // Password reset has been confirmed and new password updated.
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        logEvent("password_reset_fail", {
          message: error.message,
        });
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        console.log("Error Reset Password");
        this.setState({
          loading: false,
          validaction: false,
        });
      });
  };

  getAuthAction = async () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get("mode");
    const lang = params.get("lang") || "en";
    const code = params.get("oobCode");

    if (mode === "resetPassword") {
      //force logout
      await userLogout();
      const auth = fire.auth();

      auth
        .verifyPasswordResetCode(code)
        .then((email) => {
          logEvent("verify_password_reset_code_success", {
            email: email,
          });
          //password is using proper code
          this.setState({
            query: {
              mode,
              lang,
              code,
              email,
            },
            data: {
              password: "",
            },
            loading: false,
            validaction: true,
            authcommand: mode,
          });
        })
        .catch((error) => {
          logEvent("verify_password_reset_code_fail", {
            message: error.message,
          });
          // Invalid or expired action code.
          // Ask user to try to reset the password again.
          this.setState({
            loading: false,
            validaction: false,
            message: error.message,
            authcommand: mode,
          });
        });
    } else if (mode === "verifyEmail") {
      //force logout
      await userLogout();

      try {
        const auth = fire.auth();
        // Try to apply the email verification code.
        await auth.applyActionCode(code);
        var curUser = auth.currentUser;

        if (curUser) {
          // User is signed in.
          await auth.currentUser.reload();
        }
        logEvent("verify_email_success", {
          message: "Verify email success",
        });
        this.setState({
          loading: false,
          validaction: true,
          authcommand: mode,
          validatesuccess: true,
        });
      } catch (error) {
        logEvent("verify_email_fail", {
          message: error.message,
        });
        this.setState({
          loading: false,
          validaction: false,
          validatesuccess: false,

          message: error.message,
          authcommand: mode,
        });
      }

      //   auth
      //     .applyActionCode(code)
      //     .then(async (resp) => {
      //       // Email address has been verified.
      //       // TODO: Display a confirmation message to the user.
      //       // You could also provide the user with a link back to the app.
      //       // TODO: If a continue URL is available, display a button which on
      //       // click redirects the user back to the app via continueUrl with
      //       // additional state determined from that URL's parameters.
      //       await auth.currentUser.reload();
      //       this.setState({
      //         loading: false,
      //         validaction: true,
      //         authcommand: mode,
      //         validatesuccess: true,
      //       });
      //       // this.props.history.push("/account/success");
      //     })
      //     .catch((error) => {
      //       // Code is invalid or expired. Ask the user to verify their email address
      //       // again.
      //       this.setState({
      //         loading: false,
      //         validaction: false,
      //         validatesuccess: false,

      //         message: error.message,
      //         authcommand: mode,
      //       });
      //     });
    } else {
      this.setState({
        loading: false,
        validaction: false,
      });
    }
  };

  resetPasswordContent() {
    const { loading, validaction, resetsuccess } = this.state;

    let content;
    if (!resetsuccess) {
      content = (
        <React.Fragment>
          <div className="pb-3 pt-3">
            <h1 className="pb-2">Reset your password</h1>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "password",
                "New Password",
                "password",
                "8 characters or more"
              )}
              {!loading && this.renderBlockButton("Reset Now")}
              {loading && this.renderLoadingBlockButton("Resetting...")}
            </form>
          </div>
          <LinkButton type="link" link="/login" label="Back to Log In" />
        </React.Fragment>
      );
    } else {
      content = (
        <SimpleMessage
          title="Reset successful"
          content="Your password has been reset successfully. You may now log in."
          link="/login"
          label="Log In"
        />
      );
    }
    if (validaction === false) {
      content = (
        <SimpleMessage
          title="Ooops"
          content="Seems like the link is expired or has already been used. Please try to reset your password."
          link="/forgot"
          label="Reset Password"
        />
      );
    }
    return content;
  }

  verifyEmailContent() {
    const { validatesuccess } = this.state;

    let content;
    if (!validatesuccess) {
      content = (
        //TODO: Redirect to RESET password flow
        <SimpleMessage
          title="Ooops"
          content="Seems like the link is expired or has already been used.  Please try to reset your password."
          link="/forgot"
          label="Reset Password"
          // placement="center"
          logo="error"
        />
      );
    } else {
      content = (
        <div className="pt-5">
          <SimpleMessage
            title="Email verified"
            content="You may now login to your account."
            link="/account/setup"
            label="Login to your account"
            placement="center"
            logo="check"
          />
        </div>
      );
    }

    return content;
  }

  displayContent() {
    const { authcommand } = this.state;
    let content;

    if (authcommand === "resetPassword") {
      content = this.resetPasswordContent();
    } else if (authcommand === "verifyEmail") {
      content = this.verifyEmailContent();
    } else {
      content = (
        <SimpleMessage
          title="Ooops"
          content="Seems like you provided an invalid link."
          link="/login"
          label="Log In"
        />
      );
    }
    return content;
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getAuthAction();
  }

  render() {
    const { loading, authcommand } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }
    const content = this.displayContent();

    return (
      <React.Fragment>
        {authcommand !== "verifyEmail" && (
          <div className="pt-4 mt-4">
            <SystemLogo />
          </div>
        )}
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-4">
            <main className="container">{content}</main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AuthHandler;
