import React, { Component } from "react";
import { displayAmount, fixCents } from "../../../services/amount";
import { getTransactionOperator } from "../../../services/transaction";
import { getNormalShortDate } from "../../../utils/utils";

class TransactionList extends Component {
  render() {
    const { items } = this.props;
    return (
      <React.Fragment>
        <div className="card my-3">
          <div className="card-body">
            {items &&
              items.map((item) => {
                let amount = fixCents(item.amount);
                return (
                  <div key={item.timestamp} className="pt-3">
                    <div className="float-left font-weight-light">
                      {getNormalShortDate(new Date(item.timestamp))}
                    </div>
                    <div className="float-right">
                      {getTransactionOperator(item)} {displayAmount(amount)}
                    </div>
                    <div className="clearfix"></div>
                    <div className="float-left font-weight-light clearfix text-muted">
                      {item.header}
                    </div>
                    <div className="clearfix pt-4 mt-3">
                      <hr />
                    </div>
                  </div>
                );
              })}
            <p className="text-center text-muted font-weight-light mb-0 pb-0">
              End of Record
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TransactionList;
