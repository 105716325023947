import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import fire from "./services/fire";
import Home from "./core/home";
import LoadingSpinner from "./core/common/loadingSpinner";
import RegisterForm from "./core/account/page/register";
import LoginForm from "./core/account/page/login";
import AccountSetup from "./core/account/page/accountSetup";
import AccountSetupSuccess from "./core/account/page/accountSetupSuccess";
import ForgotPassword from "./core/account/page/forgot";
import RegisterSuccess from "./core/account/page/registerSuccess";
import AuthHandler from "./core/account/page/auth";
import PageNotFound from "./core/common/pageNotFound";
import ProfilePage from "./core/account/page/profile";
// import ParishDetails from "./core/parish/parishDetailsPage";
// import ParishListing from "./core/parish/parishListingPage";
// import Feedback from "./core/feedback/page/addFeedback";
// import ViewAnnouncement from "./core/announcement/page/viewAnnouncement";
// import ViewAllAnnouncement from "./core/announcement/page/viewAllAnnouncement";
// import ViewPrayer from "./core/prayer/page/viewPrayer";
// import ViewAllPrayer from "./core/prayer/page/viewAllPrayer";
import AccountVerify from "./core/account/page/accountVerify";
// import UserGuide from "./core/userguide/page/userGuide";
// import Faq from "./core/faq/page/faq";
// import ReviewMassBooking from "./core/mass/page/reviewMassBooking";
// // import ConfigureParishes from "./core/config/page/configureParishes";
// // import ConfigureMasses from "./core/config/page/configureMassTimings";
// import PrayerSetup from "./core/prayer/admin/setupPrayer";
// import SuperAdminPrayerSetup from "./core/prayer/admin/superAdminSetupPrayer";
// import ArchdiocesePrayerSetup from "./core/prayer/admin/archdioceseSetupPrayer";

// import SuccessMassBooking from "./core/mass/page/successMassBooking";
// import AnnouncementSetup from "./core/announcement/admin/setupAnnouncement";
// // import ParishionerProfilePage from "./core/profile/page/parishionerProfile";
// import ViewFeedback from "./core/feedback/admin/viewFeedback";
// import SuperAdminAnnouncementSetup from "./core/announcement/admin/superAdminSetupAnnouncement";
// import ArchdioceseAnnouncementSetup from "./core/announcement/admin/archdioceseSetupAnnouncement";
// import AdminDashboard from "./core/admin/page/adminDashboard";
// import ParishionerList from "./core/admin/page/parishionerList";
// // // import ParishPriest from "./core/blocks/parishPriestPhotoBlock";
// import Priest from "./core/priest/page/priest";
// import RolesSetup from "./core/roles/page/rolesDashboard";
// import ParishionerDashboard from "./core/admin/page/parishionerDashboard";
// import Schedule from "./core/schedule/page/schedule";
// import MassSchedules from "./core/mass/page/massSchedule";
// import ConfigureMass from "./core/config/page/configMass";
// import MassParishes from "./core/mass/page/massParishesPage";
// // import ComingSoon from "./core/common/comingSoon";
// import MassBookingHistory from "./core/mass/page/massBookingHistory";
// import MassAttendance from "./core/massattendance/page/massAttendance";
// // import VerifyParishionerRegistration from "./core/verify/page/verifyParishionerRegistration";
// import MassBookingsDashboard from "./core/massbookings/page/massBookingsDashboard";
// import MassBookingSchedules from "./core/massbookings/page/massBookingSchedules";
// import MassBookingSelector from "./core/massbookings/page/massBookingSelector";
// import BookedParishionerList from "./core/massbookings/page/bookedParishionerList";
// import CheckBranchCode from "./core/branchcode/page/checkBranchCode";
// import AccountManager from "./core/accountmanager/page/accountManager";
// import AccountManagerEdit from "./core/accountmanager/page/accountManagerEdit";
// import AccountManagerView from "./core/accountmanager/page/accountManagerView";
// import AccountManagerIdEdit from "./core/accountmanager/page/accountManagerIdEdit";
// import AccountManagerParishEdit from "./core/accountmanager/page/accountManagerParishEdit";
// import StatsDashboard from "./core/stats/page/stats";
// import MassSelector from "./core/mass/page/massSelector";
// import AddMassSchedules from "./core/mass/admin/addMassSchedules";
// import BarcodeScanMassAttendance from "./core/massattendance/page/barcodeScanMassAttendance";
// import AccountManagerEmailEdit from "./core/accountmanager/page/accountManagerEmailEdit";
// import PreviewDraftMassSchedules from "./core/mass/admin/previewDraftMassSchedules";
import AccountEdit from "./core/account/page/accountEdit";
// import SupportParishListing from "./core/support/supportParishListingPage";
// import SupportParishDetails from "./core/support/supportParishDetailsPage";
// import ParishPriestList from "./core/priest/page/parishPriest";
// import Updates from "./core/updates/page/updates";
// import IncreaseMass from "./core/config/page/increaseMass";
import ToPayAddPage from "./core/topay/page/toPayAddPage";
import ToPayViewAllPage from "./core/topay/page/toPayViewAllPage";
import ToPayEditPage from "./core/topay/page/toPayEditPage";
import ProtectedRoute from "./core/route/protectedRoute";
import ToPayViewPage from "./core/topay/page/toPayViewPage";
import ToPayFullPage from "./core/topay/page/toPayFullPage";
import ToPayPartialPage from "./core/topay/page/toPayPartialPage";
import ToPayViewPaidPage from "./core/topay/page/toPayViewPaidPage";
import ToCollectViewAllPage from "./core/tocollect/page/toCollectViewAllPage";
import ToCollectAddPage from "./core/tocollect/page/toCollectAddPage";
import ToCollectViewPage from "./core/tocollect/page/toCollectViewPage";
import ToCollectEditPage from "./core/tocollect/page/toCollectEditPage";
import ToCollectPartialPage from "./core/tocollect/page/toCollectPartialPage";
import ToCollectFullPage from "./core/tocollect/page/toCollectFullPage";
import ToCollectViewPaidPage from "./core/tocollect/page/toCollectViewPaidPage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.archbishop) {
            user.archbishop = idTokenResult.claims.archbishop;
          } else if (idTokenResult.claims.archcomms) {
            user.archcomms = idTokenResult.claims.archcomms;
          } else if (idTokenResult.claims.parishpriest) {
            user.parishpriest = idTokenResult.claims.parishpriest;
          } else if (idTokenResult.claims.secretary) {
            user.secretary = idTokenResult.claims.secretary;
          } else if (idTokenResult.claims.superadmin) {
            user.superadmin = idTokenResult.claims.superadmin;
          } else if (idTokenResult.claims.support) {
            user.support = idTokenResult.claims.support;
          } else if (idTokenResult.claims.systemadmin) {
            user.systemadmin = idTokenResult.claims.systemadmin;
          } else if (idTokenResult.claims.systemvalidator) {
            user.systemvalidator = idTokenResult.claims.systemvalidator;
          } else if (idTokenResult.claims.apiconsumer) {
            user.apiconsumer = idTokenResult.claims.apiconsumer;
          }
          this.setState({ user, loading: false });
        });
      } else {
        this.setState({ user: null, loading: false });
      }
    });
  }

  render() {
    const { user, loading } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <Switch>
          {/*
          NO LOGIN REQUIRED
          */}
          {/* <Route
            path="/help"
            render={(props) => {
              return <UserGuide {...props} user={user} />;
            }}
          />
          <Route
            path="/faq"
            render={(props) => {
              return <Faq {...props} user={user} />;
            }}
          />
          <Route
            path="/updates"
            render={(props) => {
              return <Updates {...props} user={user} />;
            }}
          />
          <Route
            path="/schedule"
            render={(props) => {
              return <Schedule {...props} user={user} />;
            }}
          /> */}
          {/* <Route
            path="*"
            render={(props) => {
              if (!user) {
                return <ComingSoon id={rand} />;
              }
              return <ComingSoon id={rand} {...props} user={user} />;
            }}
          />
          <Redirect from="/" to="/home" /> */}
          <Route
            path="/auth"
            render={(props) => {
              return <AuthHandler {...props} user={user} />;
            }}
          />
          <Route
            path="/register"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterForm {...props} user={user} />;
            }}
          />
          <Route
            path="/forgot"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <ForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/login"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <LoginForm {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/feedback"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <Feedback {...props} user={user} />;
            }}
          />
          <Route
            path="/mymassbookings"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassBookingHistory {...props} user={user} />;
            }}
          />
          <Route
            path="/attendance"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassAttendance {...props} user={user} />;
            }}
          />
          <Route
            path="/scan"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <BarcodeScanMassAttendance {...props} user={user} />;
            }}
          />
          <Route
            path="/checkbranchcode/:direction"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <CheckBranchCode {...props} user={user} />;
            }}
          /> */}
          {/* <Route
            path="/verify"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <VerifyParishionerRegistration {...props} user={user} />;
            }}
          /> */}
          {/* <Route
            path="/masses/selector/:parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassSelector {...props} user={user} />;
            }}
          />
          <Route
            path="/masses/booking/success/:year/:month/:schedule"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <SuccessMassBooking {...props} user={user} />;
            }}
          />
          <Route
            path="/masses/:parish/review/:year/:month/:schedule"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ReviewMassBooking {...props} user={user} />;
            }}
          />
          <Route
            path="/masses/:parish/:year/:month"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassSchedules {...props} user={user} />;
            }}
          />
          <Route
            path="/masses"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassParishes {...props} user={user} />;
            }}
          />
          <Route
            path="/parish/:parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishDetails {...props} user={user} />;
            }}
          />
          <Route
            path="/parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishListing {...props} user={user} />;
            }}
          />
          <Route
            path="/support/:parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <SupportParishDetails {...props} user={user} />;
            }}
          />
          <Route
            path="/support"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <SupportParishListing {...props} user={user} />;
            }}
          />
          <Route
            path="/priest/:parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <Priest {...props} user={user} />;
            }}
          />
          <Route
            path="/priest"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishPriestList {...props} user={user} />;
            }}
          /> */}

          <Route
            path="/account/created"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              }
              return <RegisterSuccess {...props} user={user} />;
            }}
          />
          <Route
            path="/account/setup"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              }
              return <AccountSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/account/edit"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              }
              return <AccountEdit {...props} user={user} />;
            }}
          />
          <Route
            path="/account/verify"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              }
              return <AccountVerify {...props} user={user} />;
            }}
          />
          <Route
            path="/account/success"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountSetupSuccess {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/profile/:id"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishionerProfilePage {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/profile"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <ProfilePage {...props} user={user} />;
            }}
          />
          {/* <Route path="/configureparish" component={ConfigureParishes} />
          <Route path="/configuremasses" component={ConfigureMasses} /> */}
          {/* <Route
            path="/commandcenter/configure/mass"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ConfigureMass {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/increase/mass"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <IncreaseMass {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/announcement/suadmin"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <SuperAdminAnnouncementSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/announcement/archadmin"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ArchdioceseAnnouncementSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/announcement/view/:announcementid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ViewAnnouncement {...props} user={user} />;
            }}
          />
          <Route
            path="/announcement"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ViewAllAnnouncement {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/prayer/archadmin"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ArchdiocesePrayerSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/prayer/suadmin"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <SuperAdminPrayerSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/prayer/view/:prayerid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ViewPrayer {...props} user={user} />;
            }}
          />
          <Route
            path="/prayer"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ViewAllPrayer {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/roles"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <RolesSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/preview/massbookings/:parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <PreviewDraftMassSchedules {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/massbookings/add"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AddMassSchedules {...props} user={user} />;
            }}
          />
          <Route
            path="/massbookings/selector/:parish"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassBookingSelector {...props} user={user} />;
            }}
          />

          <Route
            path="/massbookings/:parish/:year/:month"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassBookingSchedules {...props} user={user} />;
            }}
          />
          <Route
            path="/massbookings/:parishId/:massId"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <BookedParishionerList {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/massbookings/dashboard"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <MassBookingsDashboard {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/parishioner/dashboard"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishionerDashboard {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/parishioners/:parishId"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishionerList {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/parishioners"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ParishionerList {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/announcement"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AnnouncementSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/prayer"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <PrayerSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/stats"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <StatsDashboard {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/feedback"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <ViewFeedback {...props} user={user} />;
            }}
          />

          <Route
            path="/commandcenter/accountmanager/view/:parishionerid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountManagerView {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/accountmanager/idedit/:parishionerid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountManagerIdEdit {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/accountmanager/emailedit/:parishionerid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountManagerEmailEdit {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/accountmanager/parishedit/:parishionerid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountManagerParishEdit {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/accountmanager/edit/:parishionerid"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountManagerEdit {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter/accountmanager"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AccountManager {...props} user={user} />;
            }}
          />
          <Route
            path="/commandcenter"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                if (!user.emailVerified) {
                  return <AccountVerify {...props} user={user} />;
                }
              }
              return <AdminDashboard {...props} user={user} />;
            }}
          /> */}
          <ProtectedRoute
            component={ToPayViewPaidPage}
            path="/topay/view/transactions/paid"
            user={user}
          />
          <ProtectedRoute
            component={ToPayAddPage}
            path="/topay/add"
            user={user}
          />
          <ProtectedRoute
            component={ToPayEditPage}
            path="/topay/edit/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToPayViewPage}
            path="/topay/view/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToPayFullPage}
            path="/topay/payfull/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToPayPartialPage}
            path="/topay/paypartial/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToPayViewAllPage}
            path="/topay/viewall"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectViewPaidPage}
            path="/tocollect/view/transactions/paid"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectViewAllPage}
            path="/tocollect/viewall"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectAddPage}
            path="/tocollect/add"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectViewPage}
            path="/tocollect/view/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectEditPage}
            path="/tocollect/edit/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectPartialPage}
            path="/tocollect/collectpartial/:id"
            user={user}
          />
          <ProtectedRoute
            component={ToCollectFullPage}
            path="/tocollect/collectfull/:id"
            user={user}
          />
          <ProtectedRoute component={Home} path="/home" user={user} />

          <Redirect from="/" exact to="/home" />
          <Route
            path="*"
            render={(props) => {
              if (!user) {
                return <PageNotFound />;
              }
              return <PageNotFound {...props} user={user} />;
            }}
          />
          <Route
            path="/404"
            render={(props) => {
              if (!user) {
                return <PageNotFound />;
              }
              return <PageNotFound {...props} user={user} />;
            }}
          />
        </Switch>
        <div className="clearfix my-4">&nbsp;</div>
      </React.Fragment>
    );
  }
}

export default App;
