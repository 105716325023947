import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import Header from "../../common/header";
import { maskDOB } from "../../../utils/utils";
import { getCurrentUserData, userLogout } from "../../../services/user";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import MyProfileCardPanel from "../../blocks/myProfileCardPanelBlock";
import LinkButton from "../../blocks/linkButtonBlock";
import { logEvent } from "../../../services/log";
import {
  enableEditBasicProfile,
  enableProfilePhotoUpload,
} from "../../../services/settings";
import { isValidFullname } from "../../../services/validation";
import SystemBarError from "../../common/systemBarError";
import ProfileFullname from "../../profile/component/profileFullname";
import ProfileVerifyStatus from "../../profile/component/profileVerifyStatus";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { Grid, Slider } from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import fire from "../../../services/fire";
import { updateProfilePic } from "../../../services/profile";
import SpinnerText from "../../common/spinnerwithText";
import SuccessMessage from "../../common/successMessageBox";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fullname: "",
        email: "",
        mobile: "",
        dob: "",
        subid: "",
        identification: "",
        parish: 0,
      },
      user: this.props.user,
      imageDataUrl: "",
      showphotoedit: false,
      saving: false,
      loading: true,
      zoom: 1,
    };
  }

  async getCurrentUserData() {
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        fullname,
        email,
        mobile,
        dob,
        identification,
        subid,
        parish,
        lastmassbooking,
        bigpic,
      } = user;

      // if (!parish) {
      //   logEvent("profile_load", { email, message: "First time setup" });
      //   this.props.history.push("/account/setup");
      //   return;
      // }

      logEvent("profile_load", { email, parish });
      let isvalidfullname = isValidFullname(fullname);

      const photoUrl = bigpic;

      this.setState({
        isvalidfullname,
        data: {
          fullname,
          email,
          mobile,
          dob: maskDOB(dob),
          identification,
          subid,
          parish,
          lastmassbooking,
        },
        photoUrl,
        loading: false,
      });
    } else {
      await userLogout();
      window.location.reload();
    }
  }

  componentDidMount() {
    this.getCurrentUserData();
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      logEvent("logout_click", { email: data.email });
      await userLogout();
      window.location.reload();

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  doEdit = () => {
    this.props.history.push("/account/edit");
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { data } = this.state;
      const file = e.target.files[0];
      let imageDataUrl = await this.readFile(file);

      logEvent("profile_new_photo_upload", { email: data.email });

      this.setState({ imageDataUrl, showphotoedit: true });
    }
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  onClickCancel = () => {
    this.setState({ showphotoedit: false });
  };

  onClickSave = async () => {
    if (this.editor) {
      const { data } = this.state;

      this.setState({ saving: true });
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const userid = this.state.user.uid;
      const uploadpath = `images/${userid}`;
      const filename = `${userid}.jpg`;

      fire
        .storage()
        .ref(uploadpath)
        .child(filename)
        .putString(canvasScaled.toDataURL(), "data_url")
        .then(async (snapshot) => {
          await updateProfilePic(userid, filename);
          logEvent("profile_photo_changed_success", { email: data.email });

          this.setState({
            newprofileupload: true,
            saving: false,
            showphotoedit: false,
            photoUrl: canvasScaled.toDataURL(),
          });
        });
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  increaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 3;
    if (zoom < 3) {
      newzoom = zoom + 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  decreaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 1;
    if (zoom > 1) {
      newzoom = zoom - 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  reloadProfile = () => {
    window.location.reload();
  };
  render() {
    const {
      data,
      loading,
      user,
      isvalidfullname,
      photoUrl,
      zoom,
      showphotoedit,
      imageDataUrl,
      saving,
      newprofileupload,
    } = this.state;

    const enablePicUpload = enableProfilePhotoUpload();
    const { fullname } = data;

    if (loading === true) {
      return <LoadingSpinnerNav type="backonly" hide="profile" />;
    }

    let showedit = false;
    if (enableEditBasicProfile()) {
      showedit = true;
    }
    if (newprofileupload) {
      return (
        <SuccessMessage
          message="Your photo has been successfully saved!"
          label="Done"
        />
      );
    }
    return (
      <React.Fragment>
        <Header type="backonly" hide="profile" />
        {!isvalidfullname && (
          <SystemBarError
            text="Please update your Full Name."
            link="/account/edit"
          />
        )}
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6 text-center">
            <main className="container text-center mx-auto">
              {!showphotoedit && (
                <React.Fragment>
                  <nav className="navbar navbar-light flex-column">
                    <h1 className="mx-auto mt-4 text-black-50 text-center">
                      {!enablePicUpload && (
                        <AccountCircleIcon style={{ fontSize: "150px" }} />
                      )}
                      {enablePicUpload && (
                        <React.Fragment>
                          {!photoUrl && (
                            <AccountCircleIcon style={{ fontSize: "150px" }} />
                          )}
                          {photoUrl && (
                            <Image
                              src={photoUrl}
                              width="150px"
                              height="150px"
                              alt=""
                              roundedCircle
                            />
                          )}
                          <div className="image-upload">
                            <label
                              htmlFor="file-input"
                              className="image-upload-icon"
                            >
                              <AddAPhotoIcon />
                            </label>

                            <input
                              id="file-input"
                              type="file"
                              onChange={this.onFileChange}
                              accept="image/*"
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </h1>
                  </nav>
                  <ProfileFullname
                    isvalidfullname={isvalidfullname}
                    fullname={fullname}
                  />
                  <ProfileVerifyStatus
                    showedit={showedit}
                    requiredstatus={true}
                    status={user.emailVerified}
                  />
                  <MyProfileCardPanel data={data} showchangelink={true} />
                  <div className="mt-4 pt-4"></div>
                  <Button
                    variant="outline-primary"
                    className="btn-block btn-lg mt-4"
                    style={{ border: "1px solid #dddddd" }}
                    onClick={this.doSubmit}
                  >
                    Logout
                  </Button>
                  <LinkButton type="link" link="/home" label="Back to Home" />
                </React.Fragment>
              )}
              {showphotoedit && (
                <div className="mx-auto">
                  <h4 className="text-left py-4">Update Profile Picture</h4>
                  <div className="pb-4">
                    <AvatarEditor
                      ref={this.setEditorRef}
                      image={imageDataUrl}
                      width={250}
                      height={250}
                      border={0}
                      borderRadius={250}
                      scale={zoom}
                      crossOrigin="anonymous"
                    />
                  </div>
                  <Grid container spacing={2} className="my-2">
                    <Grid item>
                      <RemoveIcon onClick={this.decreaseZoom} />
                    </Grid>
                    <Grid item xs>
                      <Slider
                        className="text-primary"
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                      />
                    </Grid>
                    <Grid item>
                      <AddIcon onClick={this.increaseZoom} />
                    </Grid>
                  </Grid>

                  {!saving && (
                    <Button
                      variant="primary"
                      className="btn-block btn-lg"
                      onClick={this.onClickSave}
                    >
                      Save
                    </Button>
                  )}
                  {saving && (
                    <Button
                      variant="primary"
                      className="btn-block btn-lg"
                      disabled
                    >
                      <SpinnerText text="Saving..." />
                    </Button>
                  )}
                  <Button
                    variant="link"
                    className="btn-block btn-lg text-primary"
                    onClick={this.onClickCancel}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfilePage;
