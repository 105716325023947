import React, { Component } from "react";
import Header from "./common/header";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { getCurrentUserData, userLogout } from "../services/user";
import {
  getMassRegistrationLink,
  getSuperAdminCode,
  formatTime,
} from "../utils/utils";
import SystemBarError from "./common/systemBarError";
import {
  getParishLinkById,
  getParishSafeEntryIdById,
  getParishPewFormIdById,
} from "../services/parish";
import LoadingSpinnerNav from "./common/loadingSpinnerNavbar";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import MainMenuCardSimplePanel from "./blocks/mainMenuCardPanelSimpleBlock";
import { getAllAnnouncementsByParish } from "./announcement/service/announcement";
import EditIcon from "@material-ui/icons/Edit";
import AirlineSeatReclineNormalOutlinedIcon from "@material-ui/icons/AirlineSeatReclineNormalOutlined";
import SafeEntryLogo from "../img/safeentry/safeentry.png";
import Linkify from "react-linkify";
import { isValidFullname } from "../services/validation";
import Footer from "./common/footer";
import MainPanel from "./topay/component/mainPanel";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menutype: "light",
      data: {
        fullname: "",
        email: "",
        mobile: "",
        dob: "",
      },
      totaltopay: 0,
      totaltocollect: 0,
      announcementlist: [],
      systemannouncementlist: [],
      user: this.props.user,
      loading: true,
    };
  }

  getCurrentUserData = async () => {
    try {
      const uid = this.state.user.uid;
      const user = await getCurrentUserData(uid);

      if (user) {
        const {
          fullname,
          email,
          mobile,
          dob,
          created,
          totaltocollect,
          totaltopay,
        } = user;

        //check if valid fullname
        let isvalidfullname = isValidFullname(fullname);
        this.setState({
          isvalidfullname,
          data: {
            created,
            fullname,
            email,
            mobile,
            dob,
          },
          totaltopay: totaltopay || 0,
          totaltocollect: totaltocollect || 0,
          loading: false,
        });
      } else {
        //USER NOT FOUND
        //LOGOUT!!!
        await userLogout();
        window.location.reload();
      }
    } catch (error) {
      console.log("Error:", error);
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  };

  async componentDidMount() {
    await this.getCurrentUserData();
    // await this.getAnnouncements();
    window.scrollTo(0, 0);
  }

  async getAnnouncements() {
    //retrieve data from firestore
    const { parish } = this.state.data;
    if (!parish) {
      return;
    }
    try {
      const announcement = await getAllAnnouncementsByParish(parish);
      //parse here

      //extract system
      const systemnotification = announcement.filter(
        (item) => item.parish === getSuperAdminCode()
      );
      const publicnotification = announcement.filter(
        (item) => item.parish !== getSuperAdminCode()
      );
      this.setState({
        announcementlist: publicnotification,
        systemannouncementlist: systemnotification,
        loading: false,
      });
    } catch (error) {
      console.error("Announcements not yet created");
      this.setState({ loading: false });
    }
  }

  displayBasicNotification(item) {
    return (
      <Carousel.Item key={item.id}>
        <div className="d-block w-100">
          <h3>{item.header}</h3>
          <p>{item.content}</p>
        </div>
      </Carousel.Item>
    );
  }

  getAnnouncementLink(item) {
    const label = item.header;
    const link = `/announcement/view/${item.id}`;
    return <Link to={link}>{label}</Link>;
  }

  displayExternalLinks(parish) {
    let safeentrylink = getParishSafeEntryIdById(parish);
    let pewform = getParishPewFormIdById(parish);
    return (
      <div className="row">
        {(safeentrylink || pewform) && (
          <div className="clearfix my-2 col-12">
            <h5 className="">Quick Links</h5>
          </div>
        )}
        {safeentrylink && (
          <div className="mt-0 mb-5 mr-0 px-0 col text-center">
            <a target="_blank" rel="noopener noreferrer" href={safeentrylink}>
              <img
                alt="SafeEntry"
                src={SafeEntryLogo}
                height="25px"
                className="d-inline-block align-top"
              />
            </a>
          </div>
        )}

        {pewform && (
          <div className="mt-0 mb-5 ml-0 pl-0 col text-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="defaultfontsize"
              href={pewform}
            >
              <AirlineSeatReclineNormalOutlinedIcon
                className="ml-0 text-primary"
                style={{
                  fontSize: "28px",
                  marginTop: "-6px",
                  marginRight: "0px",
                }}
              />{" "}
              Pew Number
            </a>
          </div>
        )}
      </div>
    );
  }

  componentDecorator = (href, text, key) => (
    <a href={href} key={key} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  );

  displayNotification(item) {
    let substr = item.content;
    let timeago = Date.now() - item.created;
    if (timeago < 7 * 24 * 60 * 60 * 1000) {
      timeago = formatTime(item.created);
      timeago = (
        <span className={` badge badge-pill badge-danger`}>{timeago}</span>
      );
    } else {
      timeago = null;
    }
    return (
      <Carousel.Item key={item.id}>
        <div className="d-block w-100">
          <div
            className="alert alert-primary mb-0 py-3 announcementpanelblue"
            role="alert"
            key={item.id}
            style={{
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <p className="mb-0 text-truncate announcementpanelheaderblue defaultfontsize font-weight-bold">
              {this.getAnnouncementLink(item)}
            </p>
            <p className="mb-0 lineentry">
              <Linkify componentDecorator={this.componentDecorator}>
                {substr}
              </Linkify>
            </p>
            <div className="text-truncate">
              <WatchLaterOutlinedIcon
                style={{ fontSize: "14px", color: "#f00" }}
              />{" "}
              <small>
                {new Date(item.created).toDateString()} {timeago}
              </small>{" "}
            </div>
          </div>
        </div>
      </Carousel.Item>
    );
  }

  getSystemAnnouncements() {
    const { announcementlist } = this.state;

    let content;
    content = (
      <React.Fragment>
        {announcementlist.length > 0 && (
          <Carousel indicators={false} controls={true}>
            {announcementlist.map((item) => {
              return this.displayNotification(item);
            })}
          </Carousel>
        )}
      </React.Fragment>
    );

    return content;
  }

  getMainMenuPanels = (parish) => {
    const { parishcode } = this.state.data;
    let parishlink = `parish/${getParishLinkById(parish)}`;
    let supportparishlink = `support/${getParishLinkById(parish)}`;
    let massbookinglink;
    let useselector = true;
    if (useselector) {
      massbookinglink = `masses/selector/${parishcode}`;
    } else {
      massbookinglink = getMassRegistrationLink(parish);
    }

    let menutype = this.state.menutype;
    let parishinfo = (
      <MainMenuCardSimplePanel
        menulink={parishlink}
        menulinklabel="Parish Information"
        menuicon="parishinfo"
        menutype={menutype}
      />
    );
    let massbooking = (
      <MainMenuCardSimplePanel
        menulink={massbookinglink}
        menulinklabel="Mass Booking"
        menuicon="registermass"
        menutype={menutype}
      />
    );
    let support = (
      <MainMenuCardSimplePanel
        menulink={supportparishlink}
        menulinklabel="Offertory & Giving"
        menuicon="support"
        menutype={menutype}
      />
    );
    // let feedback = (
    //   <MainMenuCardSimplePanel
    //     menulink="feedback"
    //     menulinklabel="Feedback"
    //     menuicon="feedback"
    //     menutype={menutype}
    //   />
    // );

    return (
      <div className="row justify-content-center">
        <div className="container">
          {parishinfo}
          {massbooking}
          {support}
          {/* {feedback} */}
        </div>
      </div>
    );
  };

  doLogout = async () => {
    try {
      await userLogout();
      window.location.reload();

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  render() {
    const {
      data,
      loading,
      user,
      isvalidfullname,
      totaltocollect,
      totaltopay,
    } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          user={user}
          smallpic={user.photoURL}
          hidehome={true}
        />
      );
    }
    let toPay = totaltopay;
    let toCollect = totaltocollect;

    return (
      <React.Fragment>
        <Header user={user} smallpic={user.photoURL} hidehome={true} />

        {!isvalidfullname && (
          <SystemBarError
            text="Please update your Full Name."
            link="/account/edit"
          />
        )}
        <div className="row justify-content-center mx-0">
          <div className="col-lg-6 px-1">
            <main className="container text-left">
              <h3 className="pt-3 mt-3 pb-1 mb-0 text-muted">Welcome,</h3>
              <h3 className="pt-0 pb-3">
                {isvalidfullname ? (
                  data.fullname
                ) : (
                  <Link to="/account/edit">
                    <strong className="text-danger">
                      {data.fullname} <EditIcon />
                    </strong>
                  </Link>
                )}
              </h3>
              <MainPanel
                text="For Collection"
                description="Total amount to collect"
                amount={toCollect}
                type="in"
                viewLink="/tocollect/viewall"
                viewLinkText="View transactions"
              />
              <MainPanel
                text="For Payment"
                description="Total amount to pay"
                amount={toPay}
                viewLink="/topay/viewall"
                viewLinkText="View transactions"
              />
              <Footer />
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
