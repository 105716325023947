import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";
import LinkButton from "../../blocks/linkButtonBlock";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { isValidFullname } from "../../../services/validation";
import { Button } from "react-bootstrap";

class ParishionerDetailsEdit extends Form {
  constructor(props) {
    super(props);
    const { fullname, dob, mobile } = this.props.userdetails;
    let isvalidfullname = isValidFullname(fullname);
    let errors = isvalidfullname
      ? {}
      : { fullname: "Valid Full Name only. No NRIC or email please." };
    this.state = {
      isvalidfullname,
      data: {
        fullname: fullname || "",
        mobile: mobile || "+63",
        dob: dob || "",
      },
      fullname,
      errors: errors,
      loading: false,
      profilesave: false,
    };
  }

  schema = {
    fullname: Joi.string().trim().required().label("Full Name"),
    mobile: Joi.string()
      .trim()
      .regex(/^\+[1-9?\s]{1}[0-9?\s]{9,14}$/)
      .min(10)
      .required()
      .label("Mobile Number"),
    dob: Joi.number().min(1890).max(2020).required().label("Year of Birth"),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  doSubmit = async () => {
    const { email, parish } = this.props.userdetails;
    const { data } = this.state;
    let isvalidfullname = isValidFullname(data.fullname);
    let err = isvalidfullname
      ? {}
      : { fullname: "Valid Full Name only. No numbers or email please." };
    this.setState({
      errors: err,
      loading: true,
    });

    if (!isvalidfullname) {
      this.setState({
        errors: err,
        loading: false,
      });
      return;
    }
    // Call the server
    try {
      //remove any spaces inside the id
      let phone = data.mobile.replace(/\s+/g, "");

      const currentUser = fire.auth().currentUser;

      await fire.firestore().doc(`users/${currentUser.uid}`).update({
        fullname: data.fullname.trim(),
        mobile: phone.trim(),
        dob: data.dob.trim(),
      });
      logEvent("user_account_update_success", {
        email,
        parish,
      });
      //set state to refresh page
      this.setState({ profilesave: true });
    } catch (ex) {
      logEvent("user_account_update_fail", {
        email: email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };

  forceLogout = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  render() {
    const { loading, profilesave } = this.state;

    return (
      <div>
        {!profilesave && (
          <React.Fragment>
            <h3 className="py-3">Edit your profile</h3>

            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "fullname",
                "FULL NAME",
                "text",
                "Your FULL NAME"
              )}
              {this.renderInput(
                "mobile",
                "Mobile Number",
                "text",
                "(Ex. +63 916 850 8096)"
              )}

              {this.renderInput("dob", "Year of Birth", "text", "(Ex. 1980)")}

              {!loading && this.renderBlockButton("Save")}
              {loading && this.renderLoadingBlockButton("Saving...")}
            </form>
            <Button
              variant="link"
              onClick={() => this.props.history.goBack()}
              className="btn-block btn-lg my-4"
            >
              Cancel
            </Button>
          </React.Fragment>
        )}

        {profilesave && (
          <React.Fragment>
            <div className="text-center">
              <CheckCircleOutlineIcon
                style={{ fontSize: "150px", color: "green" }}
              />
              <h3 className="my-4">
                Your profile has been successfully saved!
              </h3>

              <LinkButton link="/home" label="Visit Home Page" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(ParishionerDetailsEdit);
