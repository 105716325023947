import { amountToCents, fixCents } from "../../../services/amount";
import { getDefaultCurrency } from "../../../services/currency";
import fire from "../../../services/fire";
import {
  getCollectAllButtonLabel,
  getCollectPartialDescription,
  getTransactionToCollectType,
} from "../../../services/transaction";

export async function getAllToPay(userid, type) {
  const db = fire.firestore();
  const items = await db
    .collection("transactions")
    .where("type", "==", type)
    .where("userid", "==", userid)
    .orderBy("lastupdate", "desc")
    .get();

  var list = [];
  items.forEach((doc) => {
    list.push(doc.data());
  });

  return list;
}

export async function getAllToPayPaid(userid, type) {
  const db = fire.firestore();
  const items = await db
    .collection("transactions")
    .where("type", "==", type)
    .where("userid", "==", userid)
    .where("settledfull", "==", true)
    .orderBy("lastupdate", "desc")
    .get();

  var list = [];
  items.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}


export async function getAllToPaySettled(userid, type) {
  const db = fire.firestore();
  const items = await db
    .collection("transactions")
    .where("type", "==", type)
    .where("userid", "==", userid)
    .where("settledfull", "==", true)
    .orderBy("lastupdate", "desc")
    .get();

  var list = [];
  items.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function addItem(uid, item) {
  const {
    created,
    timestamp,
    payto,
    description,
    amount,
    amountString,
    amountCents,
    transactionAmount,
  } = item;
  const transaction = await fire.firestore().collection(`transactions`).doc();
  await fire
    .firestore()
    .doc(`transactions/${transaction.id}`)
    .set({
      userid: uid,
      id: transaction.id,
      currency: getDefaultCurrency(),
      created,
      timestamp,
      header: payto,
      description,
      amount,
      amountCents,
      amountString,
      transactionAmount,
      lastupdate: timestamp,
      type: getTransactionToCollectType(),
      settledfull: false,
      transactions: [item.transactions],
    });
}

export async function deleteItem(id) {
  await fire.firestore().collection("transactions").doc(id).delete();
}

export async function updateTotal(id, computedTotal) {
  await fire.firestore().doc(`users/${id}`).update({
    totaltocollect: computedTotal,
  });
}

export async function updateItem(id, item) {
  const { payto, description } = item;
  await fire.firestore().doc(`transactions/${id}`).update({
    header: payto,
    description,
  });
}

export async function updateToPayAllTransaction(
  transactions,
  id,
  amount,
  notes
) {
  let created = new Date();
  let timestamp = created.getTime();
  let description = !notes
    ? getCollectAllButtonLabel(transactions, amount)
    : notes;
  const transaction = {
    created,
    amount: Number(amount),
    amountCents: amountToCents(amount),
    amountString: fixCents(amount),
    header: description,
    timestamp,
    operator: "-",
  };
  transactions.unshift(transaction);
  await fire.firestore().doc(`transactions/${id}`).update({
    lastupdate: timestamp,
    transactions,
    amount: 0,
    amountCents: 0,
    amountString: "0",
    settledfull: true,
  });
}

export async function updateToPayPartialTransaction(
  transactions,
  id,
  currentAmount,
  newAmount,
  notes
) {
  let created = new Date();
  let timestamp = created.getTime();
  let roundAmount = fixCents(newAmount.replace(",", ""));
  let description = !notes ? getCollectPartialDescription(roundAmount) : notes;

  const transaction = {
    created,
    amount: Number(roundAmount),
    amountCents: amountToCents(roundAmount),
    amountString: fixCents(roundAmount),
    header: description,
    timestamp,
    operator: "-",
  };
  let updatedAmount = Number(currentAmount) - Number(roundAmount);
  let settled = updatedAmount === 0 ? true : false;
  //put in first to sort in descending order
  transactions.unshift(transaction);
  // console.log(
  //   `Updated Amount: ${updatedAmount} Current: ${currentAmount} Round: ${roundAmount}`
  // );
  await fire
    .firestore()
    .doc(`transactions/${id}`)
    .update({
      lastupdate: timestamp,
      transactions,
      amount: Number(updatedAmount),
      amountCents: amountToCents(Number(updatedAmount)),
      amountString: fixCents(Number(updatedAmount)),
      settledfull: settled,
    });
}

export async function getItemToPay(userid, id, type) {
  const db = fire.firestore();

  const items = await db
    .collection("transactions")
    .where("id", "==", id)
    .where("type", "==", type)
    .where("userid", "==", userid)
    .orderBy("timestamp", "asc")
    .limit(1)
    .get();

  var list = [];
  items.forEach((doc) => {
    list.push(doc.data());
  });

  return list;
}
