import React, { Component } from "react";
import ToCollectAdd from "../component/toCollectAdd";
import Header from "../../common/header";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import SuccessMessageBox from "../../common/successMessageBox";

class ToCollectAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        payto: "",
        amount: 0,
        description: "",
      },
      id: this.props.match.params.id,
      user: this.props.user,
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  addSuccess = () => {
    this.setState({ actionSuccess: true });
  };

  render() {
    const { loading, user, actionSuccess } = this.state;
    if (actionSuccess) {
      return (
        <SuccessMessageBox
          message="This has been added successfully!"
          label="Done"
          page="/tocollect/viewall"
        />
      );
    }
    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backonly"
          smallpic={user.photoURL}
          hide="profile"
        />
      );
    }
    return (
      <React.Fragment>
        <Header type="backonly" smallpic={user.photoURL} hide="profile" />

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-3">
                <ToCollectAdd user={user} action={this.addSuccess} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ToCollectAddPage;
