import React, { Component } from "react";
import { getCurrentUserData, userLogout } from "../../../services/user";
import Header from "../../common/header";
import MainPanel from "../component/mainPanel";
import { getAllToPay, updateTotal } from "../service/tocollect";
import ToCollectCard from "../component/toCollectCard";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import { getTransactionToCollectType } from "../../../services/transaction";
import { computeTotalAmountToPay, fixCents } from "../../../services/amount";
import BottomPanel from "../component/bottomPanel";
import LinkButton from "../../blocks/linkButtonBlock";
import { Link, withRouter } from "react-router-dom";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";

class ToCollectViewAllPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        email: "",
        dob: "",
        mobile: "+65",
      },
      errors: {},
      items: [],
      loading: true,
    };
  }

  async getItems() {
    const uid = this.state.user.uid;
    return await getAllToPay(uid, getTransactionToCollectType());
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        userid,
        created,
        fullname,
        email,
        mobile,
        dob,
        totaltopay,
      } = user;
      const items = await this.getItems();
      let computedTotal = computeTotalAmountToPay(items);
      //update total
      if (totaltopay !== computedTotal) {
        //save if no change happen
        await updateTotal(userid, computedTotal);
      }
      this.setState({
        data: {
          userid,
          created,
          fullname,
          email,
          mobile,
          dob,
        },
        totaltopay: fixCents(computedTotal),
        items,
        loading: false,
        user: this.state.user,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, items, user, totaltopay } = this.state;

    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backonly"
          smallpic={user.photoURL}
          hide="profile"
        />
      );
    }
    let toPay = totaltopay;
    const active = items.filter((item) => item.settledfull === false);
    const settled = items.filter((item) => item.settledfull === true);
    return (
      <React.Fragment>
        <Header type="backonly" smallpic={user.photoURL} hide="profile" />

        <div className="row justify-content-center  mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-3">
                <MainPanel
                  text="For Collection"
                  description="Total amount to collect"
                  type="in"
                  amount={toPay}
                  hidelink={true}
                />
                {settled?.length !== 0 && (
                  <React.Fragment>
                    <div className="clearfix float-right pt-1">
                      <Link
                        // className={`text-muted`}
                        to="/tocollect/view/transactions/paid"
                      >
                        <ListOutlinedIcon
                          style={{
                            fontSize: "16px",
                            marginTop: "-2px",
                            marginRight: "0px",
                          }}
                        />{" "}
                        View fully paid transactions
                      </Link>
                    </div>
                    <div className="clearfix"></div>
                  </React.Fragment>
                )}
                <ToCollectCard items={active} />
                <BottomPanel
                  link="/tocollect/add"
                  text="Add new amount to collect"
                  show={Number(totaltopay) === 0}
                />
                <LinkButton type="link" link="/home" label="Visit Home Page" />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ToCollectViewAllPage);
