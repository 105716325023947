import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import { getCurrentUserData, userLogout } from "../../../services/user";
import Header from "../../common/header";
import { getItemToPay, updateToPayAllTransaction } from "../service/tocollect";
import ToCollectCard from "../component/toCollectCard";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import {
  getCollectAllButtonLabel,
  getTransactions,
  getTransactionToCollectType,
} from "../../../services/transaction";
import { Button, Modal } from "react-bootstrap";
import SpinnerText from "../../common/spinnerwithText";
import {
  displayAmount,
  getAmount,
  getAmountWithCurrency,
} from "../../../services/amount";
import { withRouter } from "react-router-dom";

class ToCollectFullPage extends Form {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      id: this.props.match.params.id,
      data: {
        description: "",
      },
      errors: {},
      items: [],
      loading: true,
      showmodal: false,
    };
  }

  schema = {
    description: Joi.string().trim().optional().allow(null, "").label("Notes"),
  };

  async getItem() {
    const uid = this.state.user.uid;
    const id = this.state.id;
    return await getItemToPay(uid, id, getTransactionToCollectType());
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const items = await this.getItem();
      let amount = getAmount(items);
      if (amount === 0) {
        this.props.history.push("/tocollect/viewall");
        return;
      }
      this.setState({
        user: this.state.user,
        payType: getCollectAllButtonLabel(items),
        displayAmount: displayAmount(amount),
        amount,
        items,
        loading: false,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  doPay = async () => {
    this.setState({ paying: true });
    const { id, items, amount, data } = this.state;
    await updateToPayAllTransaction(
      getTransactions(items),
      id,
      amount,
      data.description
    );
    this.props.history.replace(`/tocollect/view/${id}`);
  };

  handleClose = () => {
    this.setState({ paying: false, showmodal: false });
  };

  handlePayAll = () => {
    this.setState({ paying: false, showmodal: true });
  };

  doSubmit = () => {
    this.setState({ paying: false, showmodal: true });
  };

  handlePartialPay = () => {
    this.setState({ paying: false, showmodal: true });
  };

  showModal = () => {
    const { showmodal, paying, displayAmount, items } = this.state;
    if (showmodal === false) {
      return null;
    }
    return (
      <React.Fragment>
        <Modal
          show={showmodal}
          onHide={this.handleClose}
          animation={false}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title className="font-weight-bold">Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            Collected{" "}
            <span className="font-weight-bold">
              {getAmountWithCurrency(items, displayAmount)}
            </span>
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={paying}
              variant="outline-secondary"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            {paying !== true && (
              <Button variant="success" onClick={() => this.doPay()}>
                Yes, confirm
              </Button>
            )}
            {paying === true && (
              <Button disabled variant="success" onClick={() => this.doPay()}>
                <SpinnerText text="Please wait..." />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  render() {
    const { loading, items, user, payType } = this.state;
    if (loading === true) {
      return (
        <LoadingSpinnerNav
          type="backonly"
          smallpic={user.photoURL}
          hide="profile"
        />
      );
    }
    return (
      <React.Fragment>
        <Header type="backonly" smallpic={user.photoURL} hide="profile" />
        <div className="row justify-content-center  mx-0">
          <div className="col-lg-6 px-1">
            <main className="container">
              <div className="pb-3 pt-3">
                <h3 className="py-3">Collect full amount</h3>
                <ToCollectCard items={items} hideall={true} />
                <form onSubmit={this.handleSubmit}>
                  {this.renderInputFocus(
                    "description",
                    "Notes",
                    "text",
                    "Optional"
                  )}
                  {this.renderBlockButton(payType)}
                </form>
                <Button
                  variant="link"
                  onClick={() => this.props.history.goBack()}
                  className="btn-block btn-lg my-4"
                >
                  Go back
                </Button>
              </div>
              {this.showModal()}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ToCollectFullPage);
